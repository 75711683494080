import React, { useState, useMemo, useEffect, useRef } from 'react';
import './App.css'; // Make sure this import is correct

function App() {
    const [chatMessages, setChatMessages] = useState([]);
    const [userMessage, setUserMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);
    const videoRef = useRef(null); // Create a ref for the video element
    const [isReversing, setIsReversing] = useState(false); // Track if the video is playing in reverse
    const audioRef = useRef(new Audio("/music.mp3")); // Create a ref for the audio element
    const [isPopupHidden, setIsPopupHidden] = useState(false);
    const enterBtnRef = useRef(null); // Create a ref for the Enter button


        // Function to start playing music when the "Enter" button is clicked
        const startMusic = () => {
            const audioElement = audioRef.current;
            audioElement.play(); // Start playing the audio
            setIsPopupHidden(true); // Hide the popup
        };


    useEffect(() => {
        const videoElement = videoRef.current;

        const handleTimeUpdate = () => {
            if (videoElement) {
                if (!isReversing && videoElement.currentTime >= videoElement.duration) {
                    videoElement.playbackRate = -1;
                    setIsReversing(true);
                } else if (isReversing && videoElement.currentTime <= 0) {
                    videoElement.playbackRate = 1;
                    setIsReversing(false);
                }
            }
        };

        if (videoElement) {
            videoElement.playbackRate = 1;
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [isReversing]);



    useEffect(() => {
        const button = enterBtnRef.current;
        if (button) {
            const handleClick = () => startMusic();
            button.addEventListener('click', handleClick);

            return () => {
                button.removeEventListener('click', handleClick);
            };
        }
    }, []); // Empty dependency array means this effect runs only once when the component mounts



    const handleChatClick = async () => {
        if (userMessage.trim() !== "" && !isCooldown) {
            setChatMessages((prevMessages) => [
                ...prevMessages,
                { role: "user", content: userMessage }
            ]);

            setIsTyping(true);
            setIsCooldown(true);

            try {
                const response = await fetch('https://api.openai.com/v1/chat/completions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                    },
                    body: JSON.stringify({
                        model: "gpt-4-turbo",
                        messages: [
                            { role: "system", content: " Imagine that you are something like a Game Master in a game. Your task is to give Sidequests to the people that are coming to you. Upon finishing each sidequest, there has to be a reward in terms of level up of some personal trait or skill. Your task is to give me a very short quest related. Make sure the quests are enteraining and unique. First you say name of the quest. Then you say, what attribute or skill do i level up finishing the sidequest and then you follow up with the very short sidequest. You talk to a 20 year old person, adjust your tone and words to that. Be chill, not cringe." },
                            ...chatMessages,
                            { role: "user", content: userMessage },
                        ],
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch chat response");
                }

                const data = await response.json();
                const aiMessage = data.choices[0].message.content;

                let index = 0;
                const typewrite = () => {
                    setChatMessages((prevMessages) => {
                        const newMessages = [...prevMessages];
                        const currentMessage = newMessages[newMessages.length - 1];
                        if (currentMessage && currentMessage.role === "assistant") {
                            currentMessage.content += aiMessage.charAt(index);
                        } else {
                            newMessages.push({ role: "assistant", content: aiMessage.charAt(index) });
                        }
                        return newMessages;
                    });

                    index++;
                    if (index < aiMessage.length) {
                        setTimeout(typewrite, 50);
                    } else {
                        setIsTyping(false);
                    }
                };
                typewrite();

                setUserMessage("");
            } catch (error) {
                console.error("Failed to fetch chat response", error);
                window.alert("Failed to connect to AI chat. Please try again later.");
                setIsTyping(false);
            } finally {
                setTimeout(() => setIsCooldown(false), 5000);
            }
        }
    };

    return (
        <div className="app-container">

       <div id="popup" className={`popup ${isPopupHidden ? 'hidden' : ''}`}>
        <div>
        <button className="enter-btn" ref={enterBtnRef} ><img src="enter.png" alt="Hero Image" /></button>
        </div>
    </div>
            {/* Video background */}
            <div className="videobg-desktop">
            <video ref={videoRef} className="background-video" autoPlay loop muted>
                <source src="/bg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>


            <div className="videobg-mobile">
            <video ref={videoRef} className="background-video" autoPlay loop muted>
                <source src="/bgmobile.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>


            {/* Social Media Links */}
            <div className="social-links">
                <a href="https://x.com/SidequestAI" target="_blank" rel="noopener noreferrer">
                    <img src="/x.png" alt="Twitter" />
                </a>
                <a href="https://pump.fun/73Gp2FAfgQAcE5LFcaAwBBQ2xDVY4ZnTjDMwqxHNpump" target="_blank" rel="noopener noreferrer">
                    <img src="/pf.png" alt="Pump.fun" />
                </a>
                <a href="https://t.me/SolSidequest" target="_blank" rel="noopener noreferrer">
                    <img src="/tg.webp" alt="Telegram" />
                </a>
            </div>

            {/* Chat Section */}
            <div className="ChatSection">
                <div className="centered-text">
                    <h1>Ready for another Sidequest to max out your stats?</h1>
                </div>
                <div className="ChatLayout">
                    <div className="ChatLeftImage">
                        <img src="/img.png" alt="Chat Image" />
                    </div>
                    <div className="ChatRightSection">
                        <div className="ChatInputWindow">
                            <input
                                className="ChatInput"
                                type="text"
                                value={userMessage}
                                onChange={(e) => setUserMessage(e.target.value)}
                                placeholder="Tell me the Skill or Trait you want to level up"
                                disabled={isCooldown}
                            />
                            <button
                                className="SendButton"
                                onClick={handleChatClick}
                                disabled={userMessage.trim() === "" || isCooldown}
                            >
                                Send
                            </button>
                        </div>

                        <div className="ChatResultWindow">
                            <div className="ChatBox">
                                {chatMessages.map((message, index) => (
                                    <div key={index} className="ChatMessage" style={{ textAlign: message.role === "user" ? 'right' : 'left' }}>
                                        <strong>{message.role === "user" ? "You" : "Your quest"}:</strong> {message.content}
                                    </div>
                                ))}
                                {isTyping && (
                                    <div className="ChatMessage" style={{ textAlign: 'left' }}>
                                        <strong>Your quest:</strong> <em>typing...</em>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
